.home {
  padding-top: 1.6875rem;
  padding-bottom: 1.6875rem;
  background-image: url(../../assets/images/gold-bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  color: var(--white);

  @media (min-width: 1200px) {
    min-height: calc(100vh - 18.786rem);
  }

  @media (max-width: 1600px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .gold-status-container {
    margin-bottom: 2rem;
    @media (max-width: 640px) {
      flex-direction: column;
      gap: 1.334rem;
    }
  }
  .status-card {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    gap: 1rem;

    @media (max-width: 640px) {
      width: 100%;
    }
    img {
      max-height: 3.5rem;
    }
    .gold-content,
    .current-time {
      display: flex;
      flex-direction: column;
      color: var(--white);
      gap: 0.5rem;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding-left: 16px;
      }
      strong {
        font-size: 1.5rem;
        line-height: 1;
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          margin-bottom: 0.5rem;
        }
      }
      small {
        line-height: 1;
        font-size: 1.125rem;
      }
    }
  }
  .social-link {
    display: flex;
    gap: 3.125rem;
    @media (max-width: 1199px) {
      gap: 1rem;
    }

    @media (max-width: 640px) {
      width: 100%;
      order: -1;
      justify-content: space-between;
    }
    a {
      width: 5.5rem;
      height: 5.5rem;
      min-height: 5.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      @media (max-width: 1199px) {
        width: 3rem;
        height: 3rem;
        min-height: 3rem;
      }
      @media (max-width: 640px) {
        width: 5.334rem;
        height: 5.334rem;
        min-height: 5.334rem;
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
      img {
        max-width: 3rem;
        @media (max-width: 1199px) {
          max-width: 1.5rem;
        }
        @media (max-width: 640px) {
          max-width: 2.5rem;
        }
      }
    }
  }
  .currentRate {
    width: 100%;
    backdrop-filter: blur(16px);
    td,
    th {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
      padding-left: 3rem;
      padding-right: 3rem;
      color: var(--white);
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      @media (min-width: 2000px) {
        font-size: 2.2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
    th {
      background-color: var(--primary);
      width: 33.33%;
      min-width: 33.33%;
      @media (max-width: 990px) {
        // min-width: 360px;
      }
      @media (max-width: 766px) {
        min-width: 260px;
      }
    }
    td {
      background: rgba(7, 7, 7, 0.95);
    }
  }
  .table-home {
    width: 100%;
    table {
      width: 100%;
    }
    @media (max-width: 766px) {
      overflow-x: auto;
    }
  }
}
