header {
  border-bottom: 5px solid var(--gold);
  background: var(--primary);
  backdrop-filter: blur(16px);
  padding: 2rem 0;
  position: relative;
  z-index: 1;
  .logo {
    img {
      height: 2rem;
      @media (max-width: 991px) {
        height: 1.334rem;
      }
    }
  }
  .language {
    color: var(--white);
    background-image: url("../assets/images/DownArrow.svg");
    -webkit-appearance: none;
    -moz-appearance: none;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      // background-image: none;
    }
  }
  .header-right {
    display: flex;
    .hamburgar {
      display: none;
      @media (max-width: 991px) {
        display: block;
      }
      &-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        span {
          background-color: var(--white);
          display: flex;
          width: 33px;
          height: 4px;
        }
      }
    }
    .menu {
      @media (max-width: 991px) {
        display: none;
        &.show {
          display: block;
          position: absolute;
          left: 0;
          top: 100%;
          background-color: var(--black);
          width: 100%;
        }
      }
    }
    .menu-list {
      display: flex;
      gap: 1.5625rem;
      margin-bottom: 0;
      align-items: center;

      @media (max-width: 991px) {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
      }
      li {
        list-style: none;

        @media (max-width: 991px) {
          width: 100%;
        }
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          padding-left: 1.5625rem;
        }
        a {
          color: var(--white);
          text-decoration: none;
          font-size: 1.25rem;
          font-weight: 500;
          &:hover {
            color: var(--gold);
          }
        }
      }
    }
  }
}
.footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.25rem;
  .icon {
    padding-right: 0.625rem;
    img {
      width: 1.25rem;
    }
  }
  .address {
    display: flex;
  }
  .footer_logo {
    margin-bottom: 1.3125rem;
  }
  li {
    margin-bottom: 0.5rem;
    a {
      color: var(--white);
      text-decoration: none;
      &:hover {
        color: var(--gold);
      }
    }
  }
  .footerTitle {
    padding-bottom: 0.9375rem;
  }
}
