@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font: "Plus Jakarta Sans", sans-serif;
  --primary: #1d1d1d;
  --dark: #070707;
  --gold: #f8d25c;
  --gold-dark: #ba8c00;
  --white: #ffffff;
  --black: #000000;
}
html {
  font-size: 16px;
  @media (min-width: 2000px) {
    font-size: 26px;
  }
  @media (min-width: 2700px) {
    font-size: 40px;
  }
  @media (max-width: 1600px) {
    font-size: 14px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
}
body {
  margin: 0;
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: auto;
  color: var(--white);
  background-color: var(--primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.gold-text {
  background: linear-gradient(
    87deg,
    var(--gold) 10.79%,
    var(--gold-dark) 97.35%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background: none;
    color: var(--gold);
  }
}
.gold-border {
  border: 2px solid var(--gold);
  background-color: var(--primary);
  backdrop-filter: blur(16px);
}
select::-ms-expand {
  display: none;
}

.container {
  @media (min-width: 2000px) {
    max-width: calc(100% - 6.247vw);
  }
  @media (max-width: 991px) {
    max-width: calc(100% - 4.87vw);
  }
}
