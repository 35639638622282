.store {
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem;
  background-image: url("../../assets/images/gold-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  min-height: calc(100vh - 18.875rem);
  &-list {
    --gap: 1.625rem;
    gap: var(--gap);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @media (max-width: 640px) {
      --gap: 1rem;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-left: -0.8125rem;
      margin-right: -0.8125rem;
    }
    li {
      width: 100%;
      max-width: calc(33.33% - var(--gap));
      flex: 1 1 auto;
      display: flex;
      @media (max-width: 640px) {
        max-width: calc(51% - var(--gap));
      }
      @media (max-width: 357px) {
        max-width: 100%;
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: 33.33%;
        padding-left: 0.8125rem;
        padding-right: 0.8125rem;
        padding-top: 0.8125rem;
        padding-bottom: 0.8125rem;
      }
    }
  }
  .loading {
    color: var(--black);
  }
}
.store-card {
  background-color: var(--dark);
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  &-img {
    background-color: var(--primary);
    border-radius: 0.5rem 0.5rem 0 0;
    overflow: hidden;
    padding-top: 70%;
    position: relative;
    // height: 100%;
    img {
      position: absolute;
      top: 0%;
      max-height: 100%;
      display: block;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (max-width: 640px) {
      padding: 1rem;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 700;

      @media (max-width: 640px) {
        font-size: 1.25rem;
      }
    }
    p {
      font-size: 1.125rem;
      margin-bottom: 1.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media (max-width: 640px) {
        font-size: 0.835rem;
        margin-bottom: 1rem;
      }
    }
  }
  &-cost {
    margin-top: auto;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    padding-top: 1.5rem;
    border-top: 1px solid var(--gold);
    @media (max-width: 640px) {
      font-size: 0.835rem;
      padding-top: 1rem;
    }
    &:before {
      content: "";
      background: var(
        --gold,
        linear-gradient(87deg, var(--gold) 10.79%, var(--gold-dark) 97.35%)
      );
      height: 0.0625rem;
    }
  }
}
